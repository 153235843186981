@import '~antd/dist/antd.css';
#root{
  height: 100%;
}
/* .ant-layout {
  height: 100%;
} */
.ml-10{
  margin-left: 10px;
}
.is-show{
  display: block;
}
.is-hidden{
  display: none;
}
.ant-form{
  background: #fff !important;
  padding: 10px !important;
}

.ant-table-wrapper{
  margin-top: 20px !important;
}
.tablenotop .ant-table-wrapper{
  margin-top: 0px !important;
}
.ant-page-header-ghost{
  background: #fff !important;
  
}
.ant-input-number-handler-wrap{
  display: none;
}
/* .ant-menu{
  overflow-y: auto !important;
  overflow-x: hidden !important;
} */
.left-span{
  width: 200px;
  text-align: right;
  margin-right: 10px;
  
}
.margin-15{
  margin:15px 0
}
.flex{
  display: flex;
}
.ant-picker{
  width: 100%;
}
/* 登录 */
.login-tab{
  position: absolute;
  width:350px;
  height: 340px;
  top: 0;
  bottom: 0;
  right: 15%;
  margin: auto 0;
  /* margin: 10% 15% 0 0 !important; */
  background-color: #fff;
  padding: 30px !important;
  border-radius: 15px;
}
.site-form-item-icon{
  color: rgba(0, 0, 0, 0.85) !important;
}
.login-wrap{
  position: relative;
  background: url('./images/login.jpg') no-repeat center;
  height: 100%;
  background-size: 100% 100%;
  display: flex;
  justify-content: flex-end;
}
.copyright{
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: 500;
  color: #fff;
}
.logo-txt{
  color: #fff;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  left: 15%;
}
.logo-t1{
  width: 150px;
  height: 43px;
}
.sysname{
  font-size: 24px;
  margin-top: 20px;
  color: #fff;
  font-weight: 700;
  line-height: 24px;
}
.systits{
  font-size: 24px;
  color: #fff;
  line-height: 24px;
  margin-top: 20px;
}
.sysline{
  width: 100px;
  height: 4px;
  background: #fff;
  margin-top: 30px;
}
.login-form,
.login-form .ant-input-affix-wrapper,
.login-form .ant-input{
  background: transparent !important;
}
.login-form .ant-input{
  height: 28px;
}
.ant-input-affix-wrapper{
  border: 1px solid #f6f8fd;
}
/* .login-form .ant-input-prefix,
.login-form .ant-input{
  color: #ffffff7a;
} */
.login-form-button{
  width: 100%;
  border-radius: 20px !important;
  height: 42px !important;
  margin-top: 30px !important;
  /* background: #011d37; */
  border: none;
}
.login-form-button:hover, .login-form-button:focus{
  /* background: #0f416f; */
  border: none;
}
.login-wrap .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #2553a4;
  
}
.lgcode-box{
  display: flex;
  align-items: center;
}
.lgcode-input{
  margin-right: 10px;
  flex: 1;
}
.lgcode-btn{
  width: 100px;
  height: 36px !important;
}

/* 首页 */
.home-mrbtm{
  margin-bottom: 10px;  
}
.home-title{
    /* border-left: 4px solid rgb(68, 161, 250); */
  padding-left: 10px;
  font-size: 16px;
  font-weight: 700;
  position: relative;
}
.home-title::before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 4px;
    height: 22px;
    background-color: #2553a4;
}
.unit{
  display: inline-block;
}
.topwarp{
  background-color: #fff;
  padding: 8px 20px 20px 20px;
}
.home-top{
  width: 32%;
  padding: 16px 24px;
  text-align: center;
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  font-size: 14px;
  /* color: #fff; */
}
.home-top h3{
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0px;
}
.home-toplabel{
  margin-left: 10%;
  margin-right: 30px;
  color: #00000080;
  font-size: 14px;
}
.statisticswarp{
  margin-top: 19px;
  background-color: #fff;
  padding: 8px 20px 20px 20px;
}
.flexbox{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flexbox .flexitem{
  flex: 1;
  text-align: center;
}
.flexbox .flexitem .statistitle{
  color: rgba(0, 0, 0, .5);
  margin-top: 6px;
}
.flexbox .flexitem:nth-child(1){
  border-right: 1px solid #d8d8d8;
}
.flexbox .flexitem:nth-child(2){
  border-right: 1px solid #d8d8d8;
}
.home-bottom .chartbox{
  background-color: #fff;
  padding: 5px 15px 30px 15px;
}
.home-bottom-bg-wrap{
  display: flex;
  flex-wrap: wrap;
}
.home-bottom-bg{
  width: 50%;
  height: 26vh;
  font-size: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #fff;
}
.bg1{
  background: url('./images/c1.png') no-repeat center;
  background-size: contain;
}
.bg2{
  background: url('./images/c2.png') no-repeat center;
  background-size: contain;
}
.bg3{
  background: url('./images/c3.png') no-repeat center;
  background-size: contain;
}
.bg4{
  background: url('./images/c4.png') no-repeat center;
  background-size: contain;
}
.home-chart{
  background: #fff;
  padding: 10px;
  height: 100%;
}
.home-chart h3{
  padding: 15px;
}
.myLayout{
  min-height: 100% !important;
}
.tianJinDataTabs .ant-tabs-tab-btn{
  color: #333;
  padding-left:16px;
  padding-right:16px;
  /* min-height: 100vh !important;
  max-height: 100vh; */
}
.wrapl label{
  width: 100%;
  white-space: pre-wrap;
}

.ant-table-pagination{
  margin-bottom: 0px !important;
}
