.titbox{
    color: black;
}
.tititle{
    font-size: 16px;
}
.titp{
    margin-bottom: 0;
}
.equip-h2 {
    font-size: 16px;
    border-left: 2px solid #0f85ff;
    padding-left: 10px;
    margin: 20px 0;
    color: #0f85ff;
    font-weight: bold;
}
.trafficSafetyAddForm .ant-form-item-label > label{
    white-space: normal;
    height: 36px;
}
.trafficSafetyAddForm .ant-form-item{
    margin-bottom: 18px !important;
}
.trafficSafetyEditForm .ant-input-number{
    width:100% !important;
}
.site-page-header {
    border: 1px solid rgb(235, 237, 240);
}